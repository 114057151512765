$badge-size: 26px;

.item {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;

  margin: 3rem 0;
  margin-bottom: 2rem;

  &-badge {
    display: block;
    height: $badge-size;
    min-height: $badge-size;
    width: $badge-size;
    min-width: $badge-size;
    margin-right: 30px;
    padding: 2px;
    border: 2px solid rgb(100, 100, 100);
    border-radius: 50%;

    text-align: center;
    font-size: 0.9rem;
    line-height: 1.2rem;
  }

  &-premium {
    width: 90px;
    margin-top: 1rem;
    padding: 6px 10px;
    background-color: $secondary-color;
    border-radius: 2px;
    font-size: 1rem;
    line-height: 22px;
    color: #fff;
    text-transform: uppercase;
    text-align: center;
  }

  &-title {
    display: flex;
    flex-direction: column;
    align-items: center;

    margin: 0;
    margin-left: -3px;

    font-size: 1.4rem;

    > span:first-of-type {
      align-self: flex-start;
    }
  }

  &-description {
    margin-top: 1.2rem;
  }
}


@media #{$medium-and-up} {
  .item {
    &-title {
      flex-direction: row;
      align-items: center;

      font-size: 1.8rem;
    }

    &-premium {
      margin-top: 0;
      margin-left: 1rem;
    }
  }
}
