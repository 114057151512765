.footer {
  padding: $gutter-width * 2 $gutter-width;
  background-color: darken($secondary-color, 5%);

  &-wrapper {
    @extend .container;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  &-item {
    display: flex;
    align-items: center;
    margin: 1px 8px;
    font-size: 1rem;
    font-weight: normal;
    color: #fff;
    text-align: center;
  }
}


@media #{$medium-and-up} {
  .footer {
    &-wrapper {
      flex-direction: row;
    }
  }
}
