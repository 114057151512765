$field-height: 55px;

.subscribe {
  position: relative;
  display: flex;
  align-items: stretch;
  max-width: 640px;
  margin: 0 auto;
  flex-direction: column;

  ._contrast {
    @extend .grey, .lighten-5;
  }

  &-email {
    flex-grow: 1;
    height: $field-height;
    padding: 0 2.5rem;
    border-radius: 3px 3px 0 0;
    background-color: #fff;

    ._contrast {
      @extend .grey, .lighten-5;
    }

    & > input {
      border-bottom: none !important;
      height: 100% !important;
      margin: 0 !important;

      &::placeholder {
        color: grey !important;
      }

      &:focus, &:active {
        border-bottom: none !important;
        box-shadow: none !important;
      }
    }
  }

  &-btn {
    @extend .btn;

    height: $field-height;
    padding: 0 2rem;

    box-shadow: none;
    border-radius: 0 0 3px 3px;
  }
}

.disclaimer {
  font-size: 0.9rem;
  text-align: center;
}

@media #{$medium-and-up} {
  .subscribe {
    flex-direction: row;
    width: 80%;

    &-email {
      border-radius: 3px 0 0 3px;
    }

    &-btn {
      border-radius: 0 3px 3px 0;
    }
  }
}
