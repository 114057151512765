@import 'theme';

@import 'materialize/materialize';

@import 'variables';

@import 'components/page';
@import 'components/header';
@import 'components/footer';
@import 'components/item';
@import 'components/subscribe';
@import 'components/spacer';
@import 'components/spinner';

html {
  @extend .grey, .lighten-5;
}

em {
  font-style: normal;
  font-weight: bold;
}

blockquote {
  margin-bottom: 3rem;

  overflow-x: auto;
}
