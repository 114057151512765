.page {
  position: relative;

  @extend .white;

  margin-top: -$banner-hover-mobile;
  margin-bottom: 4rem;
  padding: $gutter-width;

  font-size: 1.2rem;

  h1 {
    text-align: center;
    margin-top: 1.8rem;
    margin-bottom: 3.2rem;
    font-size: 1.4rem;
    font-weight: bold;
  }

  ul {
    padding-left: 2rem;

    li {
      list-style-type: disc;
    padding-left: 0.5rem;
    }
  }
}

@media #{$medium-and-up} {
  .page {
    margin-top: -$banner-hover;
    padding: $gutter-width * 2;

    h1 {
      font-size: 1.8rem;
    }
  }
}
