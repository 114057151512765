.header {
  display: flex;
  flex-direction: column-reverse;
}

.banner {
  display: flex;
  justify-content: center;

  height: 680px;
  margin-top: -$navbar-height-mobile;
  padding-top: $navbar-height-mobile;
  padding-bottom: $banner-hover-mobile;
  background-size: cover;
  background-position: center;

  text-align: center;
  color: $banner-font-color;
  font-size: $banner-font-size;

  &-wrapper {
    @extend .container;

    margin: 0 auto;
  }

  &-title {
    margin-top: 4rem;
    text-shadow: 0px 1px 3px rgba(0, 0, 0, 0.7);
  }

  &-subtitle {
    font-size: $banner-font-size * 0.9;
    text-shadow: 0px 1px 3px rgba(0, 0, 0, 0.7);
  }
}

@media #{$medium-and-up} {
  .banner {
    align-items: center;

    height: 740px;
    margin-top: -$navbar-height * 2;
    padding-top: $navbar-height;
    padding-bottom: $banner-hover;

    &-title {
      margin-top: 2rem;
    }
  }
}

.nav {
  background-color: transparent;
  box-shadow: none;

  &-wrapper {
  }
}
